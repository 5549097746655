import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';
import { ActionService } from './api/action.service';
import { ActionTemplateService } from './api/action-template.service';
import { EntityService } from './api/entity.service';
import { EntityStatusesService } from './api/entity-statuses.service';
import { ExecutionJobService } from './api/executionJob.service';
import { ExecutionTechCardService } from './api/execution-tech-card.service';
import { JobService } from './api/job.service';
import { MediaService } from './api/media.service';
import { ObjectsService } from './api/objects.service';
import { StepService } from './api/step.service';
import { StepAnswersService } from './api/step-answers.service';
import { StepControlService } from './api/step-control.service';
import { StepDefectsService } from './api/step-defects.service';
import { StepInfoService } from './api/step-info.service';
import { StepMeasureService } from './api/step-measure.service';
import { StepResultService } from './api/step-result.service';
import { StepStuffService } from './api/step-stuff.service';
import { StepTimesService } from './api/step-times.service';
import { StepToolService } from './api/step-tool.service';
import { StepTransitionService } from './api/step-transition.service';
import { TechCardStepControlService } from './api/tech-card-step-control.service';
import { JobTemplatesService } from "./api/job-templates.service";
import { ActionMasterTemplateService } from "./api/action-master-template.service";
import { ActionTemplateCompetenciesService } from "./api/action-template-competencies.service";
import { StepQuestionService } from "./api/step-question.service";
import { ActionTemplateReportService } from "./api/action-template-report.service";
import { ActionReportService } from "./api/action-report.service";
import { ObjectClassService } from "./api/object-class.service";
import { ObjectTypeService } from "./api/object-type.service";
import { DashboardService } from "./api/dashboard.service";
import { RemoteExpert2Service } from "./api/remote-expert2.service";
import { UserDocsDiskService } from "./api/user-docs-disk.service";
import { ReportService } from "./api/report.service";

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    ActionService,
    ActionTemplateService,
    ActionMasterTemplateService,
    EntityService,
    EntityStatusesService,
    ExecutionJobService,
    ExecutionTechCardService,
    JobService,
    JobTemplatesService,
    MediaService,
    ObjectsService,
    StepService,
    StepAnswersService,
    StepControlService,
    StepDefectsService,
    StepInfoService,
    StepMeasureService,
    StepResultService,
    StepStuffService,
    StepTimesService,
    StepToolService,
    StepTransitionService,
    TechCardStepControlService,
    ActionTemplateCompetenciesService,
    StepQuestionService,
    ActionTemplateReportService,
    ActionReportService,
    ObjectClassService,
    ObjectTypeService,
    DashboardService,
    RemoteExpert2Service,
    UserDocsDiskService,
    ReportService
  ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<any> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
