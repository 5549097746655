import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { IPhoneMaskInterface } from "../interfaces";

@Injectable({
  providedIn: 'root'
})
export class PhoneMaskService {
  private countries: IPhoneMaskInterface[] = [];

  constructor(private client: HttpClient) {
    this.init();
  }

  getByCallCode

  private init() {
    this.client.get('assets/libs/masks/countries.json')
      .pipe()
      .subscribe((data: IPhoneMaskInterface[]) => {
        this.countries = data;
        console.log('this.countries =>', this.countries);
      })
  }

  getMaskByCountryCode(iso: string): string | string[] {
    console.log('getMaskByCountryCode iso =>', iso);
    console.log('getMaskByCountryCode countries =>', this.countries);
    return this.countries.find((country) => country.iso === iso)?.mask || [];
  }

  static convertMask2Regexp(mask: string): (string | RegExp)[] {
    const parts = mask.split('');
    const result = [];
    let isRoundBracketPresent = false;

    parts.forEach((val, i) => {
      switch (val) {
        case '(': {
          result.push(val);
          isRoundBracketPresent = true;
          break;
        }
        case ')': {
          result.push(val);
          break;
        }
        case '-': {
          result.push(val);
          break;
        }
        case '#': {
          if (i === 0 || (isRoundBracketPresent && i === 1) ) {
            result.push(/[1-9]/);
          } else {
            result.push(/\d/);
          }
          break;
        }
        default: {
          result.push(val);
        }
      }
    });

    return result;
  }
}
